import React, { useState } from 'react';
import Cookies from 'js-cookie';
import './css/LoginPopup.css';
import { login } from '../../services/api'; 
import Loader from '../../components/Loader'; 

const LoginPopup = ({ onLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false); // State for loader
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loader
    setErrorMessage(''); // Reset error message

    try {
      const data = await login(email, password);

      // Save the token in cookies with proper settings
      Cookies.set('authToken', data.token, { expires: 1, secure: true, sameSite: 'Strict' });

      // Notify parent component about login and reload the page
      await onLogin(true);
      window.location.reload(false);
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'An error occurred during login.');
    } finally {
      setLoading(false); // Stop loader
    }
  };

  return (
    <div className="login-popup">
      <div className="login-popup-content">
        <h2>Login</h2>
        
        {loading ? ( // Show loader while loading
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Email:</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error */}
            <button type="submit" className="btn-login">Login</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default LoginPopup;
