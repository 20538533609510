import React from 'react';

const ApplicantsList = ({ applicants, selectedCourse }) => {
  return (
    <div className="applicants-section">
      <h2>Applicants for : {selectedCourse ? selectedCourse.title || selectedCourse.CourseName : ''}</h2>
      {applicants.length === 0 ? (
        <p>No applicants for this Post.</p>
      ) : (
        applicants.map((applicant) => (
          <div key={applicant._id} className="applicant-card">
            <h4>{applicant.applicantName}</h4>
            <p>Email: {applicant.applicantEmail}</p>
            <p>Phone: {applicant.applicantPhone}</p>
            <p>Payment: {applicant.resumeUrl}</p>
          </div>
        ))
      )}
    </div>
  );
};

export default ApplicantsList;
