import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { Autoplay } from 'swiper/modules';

const ServicesSwiper = ({swipperimages}) => {
 

  return (
    <section className="swiperSection">
      <Swiper
        spaceBetween={30}
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        modules={[Autoplay]}
        loop={true}
        breakpoints={{
          320: {
            slidesPerView: 1, // Mobile screens
            spaceBetween: 10,
          },
          480: {
            slidesPerView: 2, // Small tablets
            spaceBetween: 15,
          },
          768: {
            slidesPerView: 3, // Tablets and small laptops
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4, // Laptops and desktops
            spaceBetween: 25,
          },
          1200: {
            slidesPerView: 5, // Larger screens
            spaceBetween: 30,
          },
        }}
      >
        {swipperimages.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="imageContainer">
              <img src={image.src} alt={`Slide ${index + 1}`} className="swiperImage" />
            </div>
            <p className="swiperCaption">{image.caption}</p>
          </SwiperSlide>
        ))}
      </Swiper>
    </section>
  );
};

export default ServicesSwiper;
