import React from 'react';

const CoursesList = ({edit, courses, handleCourseClick, handleUpdateCourse, handleDeleteCourse }) => {
  
  return (
   
    <div className="jobs-section">
      <h2>Posts</h2>
      {courses.map((course) => (
        <div key={course._id} className="job-card">
          <h3>{course.CourseName || course.title}</h3>

          <p>{course.duration || course.experience} | {course.location}</p>
          <p> ₹ {course.pay || course.fees}</p>
          <button onClick={() => handleCourseClick(course)}>View Applicants</button>
          {edit===true &&(
            <>
          <button onClick={() => handleUpdateCourse(course)}>Update</button>
          <button onClick={() => handleDeleteCourse(course._id)}>Delete</button>
          </>
        )}
        </div>
      ))}
    </div>

  );
};

export default CoursesList;
