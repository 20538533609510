import React from 'react';
import './css/LifeAtVijprat.css'; // External CSS file

const benefits = [
  {
    title: "Rewards & Recognition",
    icon: "⭐",
    description: "We value hard work and acknowledge exceptional performance through rewards and recognition."
  },
  {
    title: "Health Insurance",
    icon: "🩺",
    description: "Our comprehensive health insurance plan ensures peace of mind for you and your family."
  },
  {
    title: "Wellness Program",
    icon: "🧘‍♂️",
    description: "Our wellness programs help you maintain a healthy work-life balance."
  },
  {
    title: "Hybrid Work Model",
    icon: "🏠",
    description: "Choose where you work best with our flexible hybrid work model."
  },
  {
    title: "VijPrat Swastha Nidhi",
    icon: "🌱",
    description: "A financial benefit program to support employee well-being and health."
  },
  {
    title: "Thought Leadership",
    icon: "💡",
    description: "We foster thought leadership, encouraging innovation and creative problem-solving."
  }
];

const LifeAtVijprat = () => {
  return (
    <div className="life-at-vijprat">
    
      <section className="banner">
       
        <div data-aos="fade-right"  className="banner-text">
          <h1>A Team of Super Enthusiastic People</h1>
          <p>Life at Vijprat is an experience that you can always remember.</p>
        </div>
      </section>

      {/* Benefits Section */}
      <section className="benefits">
        <h2 data-aos="fade-right" >Our Benefits</h2>
        <div className="benefits-grid">
          {benefits.map((benefit, index) => (
            <div data-aos="fade-up"  key={index} className="benefit-card">
              <div className="icon">{benefit.icon}</div>
              <div className="description">
                <p data-aos="fade-right" >{benefit.description}</p>
              </div>
              <h3>{benefit.title}</h3>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default LifeAtVijprat;
