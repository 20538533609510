import React from 'react';


import './css/ServicesPage.css';
import FooterCertificates from '../../components/FooterCertificates';
import ImageSlider from '../../components/ImageSlider';

import 'swiper/swiper-bundle.css';
import ServicesSwiper from '../../components/ServicesSwiper';
import Promo from '../../components/Promo';
import DetailsSection from '../../components/ServiceDetails';

const NetworkingServicePage = () => {
  const service = {
    name: 'Networking Solutions',
    description: 'Our networking services provide secure, scalable, and efficient solutions for businesses to connect and collaborate seamlessly.',

    images: [
      // '../assets/networking/networking4.webp',
      // '../assets/networking/networking2.jpg',
      '../assets/networking/networking3.jpg',
      '../assets/networking/networking4.jpg',
      
     
    ],
    explanation: [
      'High-performance network solutions tailored to your business needs.',
      'Secure and reliable networking infrastructure to ensure smooth operations.',
      'Scalable solutions for growing businesses.',
      'Comprehensive monitoring and management of your network to minimize downtime.',
      'Your network, optimized for performance and security.',
      'Building networks that enhance communication and collaboration.',
      'Empowering businesses with seamless networking.'
    ],
    quotes: [
      '',
      '',
      
    ],
    certificatesData: [
      {
        src: '../assets/certificates/AWS-Certified-Advanced-Networking-Specialty.png',
        alt: 'AWS Certified Advanced Networking Specialty',
      },
      {
        src: '../assets/certificates/Cisco-Certified-Network-Associate.png',
        alt: 'Cisco Certified Network Associate',
      },
      {
        src: '../assets/certificates/azure-network-engineer-associate.png',
        alt: 'azure network engineer associate',
      },
    ],
    swipperimages : [
      { src: '../assets/networkingSolutions/cloud.jpg', caption: 'Cloud ' },
      { src:'../assets/networkingSolutions/connectivity.jpg', caption: 'Connectivity' },
      { src: '../assets/networkingSolutions/monitoring.avif',caption: 'Monitoring' },
      { src:'../assets/networkingSolutions/optimization.png',caption: 'Optimization ' },
      { src: '../assets/networkingSolutions/performance.jpg', caption: 'Performance' },
      { src:'../assets/networkingSolutions/redundancy.jpg',caption: 'Redundancy' },
      { src: '../assets/networkingSolutions/reliability.jpg', caption: 'Reliability' },
      { src: '../assets/networkingSolutions/scalability.jpg',caption: 'Scalability' },
      { src: '../assets/networkingSolutions/security.jpg', caption: 'Security' },
      { src: '../assets/networkingSolutions/virtualisation.jpg', caption: 'Virtulisation' },
    ],
  };
  
 

  return (
    <section>
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
        <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      <DetailsSection images={service.images} explanation={service.explanation}/>

      {/* New Section with Background Image and Button */}
      <ServicesSwiper swipperimages={service.swipperimages}/>

      <Promo />

      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default NetworkingServicePage;
