import React,{useEffect} from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import MyNavbar from './components/Navbar';
import Footer from './components/Footer';
import CareersPage from './pages/CareersPage';
import ContactUsPage from './pages/ContactusPage';
import NotFoundPage from './pages/NotFoundPage';

import Dashboard from './pages/Management/Dashboard';
import DevOpsServicePage from './pages/Services/DevOpsServicePage';
import WebDevServicePage from './pages/Services/WebDevServicePage';
import SFServicePage from './pages/Services/SFServicePage';
import GCServicePage from './pages/Services/GCServicePage';
import AzureServicePage from './pages/Services/AzureServicePage';
import AWSServicePage from './pages/Services/AWSService';
import CourseServicePage from './pages/Services/CoursesServicePage';
import LifeAtVijprat from './pages/LifeAtVijprat';
import CustomerStories from './pages/CustomerStories';

import BlogsPage from './pages/BlogsPage';
import NetworkingServicePage from './pages/Services/NetworkingService';
import TestingServicePage from './pages/Services/TestingService';
import ProductManagementServicePage from './pages/Services/ProductManagementServices';
import MarketingServicesPage from './pages/Services/MarketingServices';
import SupportServicePage from './pages/Services/SupportServicePage';


function App() {
  const location = useLocation();
  useEffect(() => {
   
    AOS.init({
      // duration: 1000,
    once:false,
  mirror: true,


    });

  }, []);
  // Hide Navbar and Footer for error or 404 pages
  const hideHeaderFooter = location.pathname === '*' || location.pathname === '/dashboard';

  return (
    <div>
      {!hideHeaderFooter && <MyNavbar />}

      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />

        <Route path='/careers' element={<CareersPage />} />
        <Route path='/contact-us' element={<ContactUsPage />} />
        <Route path='/blogs' element={<BlogsPage/>} />
        <Route path='/services/devOps' element={<DevOpsServicePage />} />
        <Route path='/services/webAppDev' element={<WebDevServicePage />} />
        <Route path='/services/salesforce' element={<SFServicePage />} />
        <Route path='/services/googleCloud' element={<GCServicePage />} />
        <Route path='/services/azure' element={<AzureServicePage />} />
        <Route path='/services/aws' element={<AWSServicePage />} />
        <Route path='/services/trainings' element={<CourseServicePage/>} />
        <Route path='/services/networking' element={<NetworkingServicePage/>} />
        <Route path='/services/testing' element={<TestingServicePage/>} />
        <Route path='/services/productManagement' element={<ProductManagementServicePage/>} />
        <Route path='/services/DigitalMarketing' element={<MarketingServicesPage/>} />
        <Route path='/services/SupportService' element={<SupportServicePage/>} />
        <Route path='/careers' element={<CareersPage/>}/>
        <Route path='/contact-us' element={<ContactUsPage/>}/>
        
        <Route path='/life-at-vijprat' element={<LifeAtVijprat/>}/>
        <Route path='/customer-stories' element={<CustomerStories/>}/>

  
        <Route path='/dashboard' element={<Dashboard />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      {!hideHeaderFooter && <Footer />}
    </div>
  );
}

export default App;
