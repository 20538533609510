import React, { useState, useEffect } from 'react';
import './css/BlogsPage.css';
import { fetchBlog, fetchBlogid } from '../services/api';
import { IoIosMore } from "react-icons/io";
import BlogsSection from '../components/BlogsSection';
import Loader from '../components/Loader'; // Assuming you have a Loader component

const BlogsPage = () => {
  const [selectedBlog, setSelectedBlog] = useState(null);
  const [hoveredCard, setHoveredCard] = useState(null);
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading
  const [error, setError] = useState(null); // State for error handling

  // Fetch blogs on component mount
  useEffect(() => {
    const fetchBlogs = async () => {
      setLoading(true);  // Start loading
      setError(null);    // Reset error state
      try {
        const response = await fetchBlog();
        console.log(response);
        setBlogs(response); // Set fetched blogs
      } catch (error) {
        console.error('Error fetching blogs:', error);
        setError('Failed to load blogs. Please try again later.'); // Set error message
      } finally {
        setLoading(false);  // Stop loading
      }
    };

    fetchBlogs(); // Invoke blog fetch function
  }, []);

  // Handle card click: If the blog has a link, open it; otherwise, fetch full description
  const handleCardClick = async (index) => {
    const blog = blogs[index];

    if (blog.link) {
      // If link exists, open it in a new tab
      window.open(blog.link, '_blank');
    } else {
      try {
        setLoading(true); // Start loading for fetching blog details
        const response = await fetchBlogid(blog._id);
        setSelectedBlog(response); // Set full blog data
      } catch (error) {
        console.error('Error fetching blog details:', error);
        setError('Failed to load blog details. Please try again later.');
      } finally {
        setLoading(false); // Stop loading after fetching blog details
      }
    }
  };

  const handleHover = (index) => {
    setHoveredCard(index);
  };

  const handleLeave = () => {
    setHoveredCard(null);
  };

  return (
    <div className="blogs-page">
      <header className="banner">
        <h1>Our Blogs</h1>
       
      </header>
      
      <BlogsSection />

      {/* Loader and Error handling */}
      {loading ? (
        <Loader />  // Show loader while loading
      ) : error ? (
        <p className="error-message">{error}</p>  // Display error message if something goes wrong
      ) : (
        <div className="blogs-container">
          {selectedBlog ? (
            <div className="blog-detail">
              <div className="blog-image">
                {/* <img src={selectedBlog.imageUrl || '../assets/aws.jpeg'} alt={selectedBlog.title} /> */}
              </div>
              <div className="blog-info">
                <h1>{selectedBlog.title}</h1>
                {/* Render full description as HTML */}
                <div 
                  className="blog-description" 
                  dangerouslySetInnerHTML={{ __html: selectedBlog.fulldescription }} 
                />
              </div>
              <button onClick={() => setSelectedBlog(null)}>Go Back</button>
            </div>
          ) : (
            blogs.map((blog, index) => (
              <div 
                className="blog-card" 
                key={index}
                onClick={() => handleCardClick(index)}
                onMouseEnter={() => handleHover(index)}
                onMouseLeave={handleLeave}
              >
                <div className="blog-image">
                  <img src='../assets/blog.gif' alt={blog.title} />
                  {hoveredCard === index && (
                    <div className="overlay-text">
                      <p>{blog.title}</p>
                    </div>
                  )}
                </div>
                <div className="blog-info">
                  <div className="blog-icon">{blog.icon}</div>
                  <h3>{blog.title}</h3>
                  <p>{blog.description} <IoIosMore/></p>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default BlogsPage;
