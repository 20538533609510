import React from 'react';
import './css/MapBox.css'
const MapBox = ({ title, src }) => {
  return (
    <div className="map-box">
      <iframe
        title={title}
        src={src}
        width="100%"
        height="300"
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </div>
  );
};

export default MapBox;
