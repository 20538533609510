import React, { useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './css/ManagementBlogsPage.css';
import { createBlog, fetchBlog, deleteBlog, updateBlog } from '../../services/api';

const PostBlogPage = () => {
  const [isSharing, setIsSharing] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fulldescription, setFulldescription] = useState('');
  const [link, setLink] = useState('');
  const [blogs, setBlogs] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editBlogId, setEditBlogId] = useState(null);

  // Function to fetch blogs (defined now to avoid the error)
  const loadBlogs = async () => {
    try {
      const data = await fetchBlog();
      setBlogs(data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  // Fetch blogs on component mount
  useEffect(() => {
    loadBlogs();
  }, []);

  // Handle form submission (create or update blog)
  const handleSubmit = async (e) => {
    e.preventDefault();

    const blogData = {
      title,
      description,
      fulldescription: isSharing ? '' : fulldescription,
      link: isSharing ? link : '',
    };

    try {
      if (editMode) {
        // Update existing blog
        await updateBlog(editBlogId, blogData);
        setEditMode(false);
        setEditBlogId(null);
      } else {
        // Create new blog
        await createBlog(blogData);
      }
      resetForm();
      loadBlogs(); // Refresh blog list after creating/updating
    } catch (error) {
      console.error('Error submitting blog:', error);
    }
  };

  // Reset form after submission
  const resetForm = () => {
    setTitle('');
    setDescription('');
    setFulldescription('');
    setLink('');
    setIsSharing(false);
    setEditMode(false);
  };

  // Handle delete blog
  const handleDelete = async (id) => {
    try {
      await deleteBlog(id);
      setBlogs(blogs.filter((blog) => blog._id !== id)); // Remove blog from state after deletion
    } catch (error) {
      console.error('Error deleting blog:', error);
    }
  };

  // Handle edit blog
  const handleEdit = (blog) => {
    setEditBlogId(blog._id);
    setTitle(blog.title);
    setDescription(blog.description);
    setFulldescription(blog.fulldescription);
    setLink(blog.link);
    setIsSharing(!!blog.link); // If there's a link, set to sharing mode
    setEditMode(true);
  };

  return (
    <>
    <div className="post-blog-page">

      <h1>{isSharing ? 'Share External Blog' : editMode ? 'Update Blog' : 'Post a New Blog'}</h1>

      <div className="toggle-share">
        <label>
          <input type="checkbox" checked={isSharing} onChange={() => setIsSharing(!isSharing)} />
          Share someone else's blog
        </label>
      </div>

      <form onSubmit={handleSubmit} className="blog-form">
        <div className="form-group">
          <label>Title</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>

        <div className="form-group">
          <label>Description</label>
          <input
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Write your short description here..."
            required
          />
        </div>

        {!isSharing ? (
          <div className="form-group">
            <label>Full Description</label>
            <ReactQuill
              value={fulldescription}
              onChange={setFulldescription}
              placeholder="Write your full blog content here..."
              modules={PostBlogPage.modules}
              formats={PostBlogPage.formats}
              required={!editMode} // Require only when creating a new blog
            />
          </div>
        ) : (
          <div className="form-group">
            <label>Blog Link</label>
            <input
              type="url"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              required
            />
          </div>
        )}

        <button type="submit" className="submit-btn">
          {editMode ? 'Update Blog' : 'Submit'}
        </button>
      </form>
</div>
      {/* Display list of blogs */}
      <div className="blog-list">
        <h2>Blogs</h2>
        {blogs.map((blog) => (
          <div key={blog._id} className="blog-card">
            <h3>{blog.title}</h3>
            <p>{blog.description}</p>
            <div className="blog-actions">
              <button onClick={() => handleEdit(blog)}>Edit</button>
              <button onClick={() => handleDelete(blog._id)}>Delete</button>
            </div>
          </div>
        ))}
      </div>
    </>
    
  );
};

// Rich text editor configurations
PostBlogPage.modules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ size: [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
    ['link', 'image', 'video'],
    ['clean'],
  ],
};

PostBlogPage.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video',
];

export default PostBlogPage;
