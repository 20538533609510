import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import './css/ImageSlider.css';

const ImageSlider = ({ images, quotes }) => {
  return (
    <Swiper
      modules={[Autoplay]} 
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{ delay: 3000, disableOnInteraction: false }}
      loop={true}
      pagination={false}
      navigation={false}
      className="serviceSwiper"
    >
      
      {images.map((im, index) => (
        <SwiperSlide key={index}>
          <div className="imageContainertop">
            <img src={im} alt={`Slide ${index + 1}`} className="swipperimg" />
            <div className="textOverlay">
              <div className="textContent">
                <p className="serviceQuote">{quotes[index]}</p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ImageSlider;
