import React, { useState, useEffect } from 'react';

import Swal from 'sweetalert2';
import './css/JobManagementPage.css'; // Import your CSS
import { fetchJobs, fetchApplicantsForJob, postJob, deleteJob, updateJob } from '../../services/api'; // Adjust the import path as needed

import ApplicantsList from './components/ApplicantsList';
import CourseList from './components/CoursesList'
const JobManagementPage = ({ userRole, access }) => {
  const [jobs, setJobs] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [formValues, setFormValues] = useState({ title: '', experience: '', location: '', description: '' });
  const edit=userRole==='admin' ||userRole==="editor" || access?.Jobs?.edit 
  useEffect(() => {
    const loadJobs = async () => {
      try {
        const data = await fetchJobs();
        setJobs(data);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    loadJobs();
  }, []);

  const handlePostJob = async (e) => {
    e.preventDefault();
    const { title, experience, location, description } = e.target;
    try {
      await postJob({
        title: title.value,
        experience: experience.value,
        location: location.value,
        description: description.value,
      });
      Swal.fire('Success', 'Job posted successfully!', 'success');
      const data = await fetchJobs();
      setJobs(data);
    } catch (error) {
      console.error('Error posting job:', error);
    }
  };

  const handleDeleteJob = async (jobId) => {
    try {
      await deleteJob(jobId);
      Swal.fire('Success', 'Job deleted successfully!', 'success');
      const data = await fetchJobs();
      setJobs(data);
    } catch (error) {
      console.error('Error deleting job:', error);
    }
  };

  const handleUpdateJob = (job) => {
    setFormValues({
      title: job.title,
      experience: job.experience,
      location: job.location,
      description: job.description,
    });
    setSelectedJob(job);
    setShowUpdatePopup(true);
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateJob(selectedJob._id, formValues);
      Swal.fire('Success', 'Job updated successfully!', 'success');
      const data = await fetchJobs();
      setJobs(data);
      setShowUpdatePopup(false);
    } catch (error) {
      console.error('Error updating job:', error);
    }
  };

  const handleJobClick = async (job) => {
    setSelectedJob(job);
    try {
      const data = await fetchApplicantsForJob(job._id);
      setApplicants(data);
    } catch (error) {
      console.error('Error fetching applicants:', error);
    }
  };

  return (
    <div className="job-management-page">
      <h3>Jobs</h3>
      {edit &&(
      <form onSubmit={handlePostJob} className="post-job-form">
        <h2>Post a New Job</h2>
        <div>
          <label>Title:</label>
          <input type="text" name="title" required />
        </div>
        <div>
          <label>Experience:</label>
          <input type="text" name="experience" required />
        </div>
        <div>
          <label>Location:</label>
          <input type="text" name="location" required />
        </div>
        <div>
          <label>Description:</label>
          <textarea name="description" required />
        </div>
        <button type="submit">Post Job</button>
      </form>
       )}
      {/* Job and Applicants Sections */}
      <div className="jobs-applicants-container">
      <CourseList
          edit={edit}
          courses={jobs}
          handleCourseClick={handleJobClick}
          handleUpdateCourse={handleUpdateJob}
          handleDeleteCourse={handleDeleteJob}
        />
        <ApplicantsList
          applicants={applicants}
          selectedCourse={selectedJob}
        />
      </div>

      {/* Update Job Popup */}
      {showUpdatePopup && (
        <div className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={() => setShowUpdatePopup(false)}>X</button>
            <h3>Update Job</h3>
            <form onSubmit={handleSubmitUpdate}>
              <div>
                <label>Title:</label>
                <input
                  type="text"
                  value={formValues.title}
                  onChange={(e) => setFormValues({ ...formValues, title: e.target.value })}
                  required
                />
              </div>
              <div>
                <label>Experience:</label>
                <input
                  type="text"
                  value={formValues.experience}
                  onChange={(e) => setFormValues({ ...formValues, experience: e.target.value })}
                  required
                />
              </div>
              <div>
                <label>Location:</label>
                <input
                  type="text"
                  value={formValues.location}
                  onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
                  required
                />
              </div>
              <div>
                <label>Description:</label>
                <textarea
                  value={formValues.description}
                  onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
                  required
                />
              </div>
              <button type="submit">Update Job</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobManagementPage;
