import React from 'react';


import './css/ServicesPage.css';
import FooterCertificates from '../../components/FooterCertificates';
import ImageSlider from '../../components/ImageSlider';

import ServicesSwiper from '../../components/ServicesSwiper';
import Promo from '../../components/Promo';
import DetailsSection from '../../components/ServiceDetails';

const AWSServicePage = () => {
  const service = {
    name: 'AWS',
    description: 'Amazon Web Services offers reliable...',
    images: [
      '../assets/aws/aws1.webp',
      '../assets/aws/aws.jpg',
      '../assets/aws/aws1.jpg',
      '../assets/aws/aws2.jpg',
      '../assets/aws/aws5.jpeg',
    ],
    explanation: [
      // aws page Pointers :- 

"Optimize your cloud environment with expert AWS solutions.",

"Seamless migration and deployment of applications on AWS.",

"Enhance operational efficiency with automated cloud management tools.",

"End-to-end monitoring and optimization of AWS resources.",

"High availability architecture with load balancing and auto-scaling.",

",Robust data backup, recovery, and disaster management on AWS.",

"Custom cloud solutions tailored to your business needs.",

"24/7 support for AWS services, ensuring uninterrupted performance.",

    ],
    quotes: [
      '',
      'Scalable and secure services, anytime, anywhere.',
      'Accelerating innovation through AWS.',
      'Optimize and transform with AWS.',
      'Your cloud journey, powered by AWS.'
    ],
    certificatesData: [
      {
        src: '../assets/certificates/AWS-security-specialty.png',
        alt: 'AWS Security Specialty',
      },
      {
        src: '../assets/certificates/AWS-Certified-Solutions-Architect-Professional_badge.png',
        alt: 'AWS Certified Solutions Architect',
      },
      {
        src: '../assets/certificates/AWS-Certified-DevOps-Engineer-Professional.png',
        alt: 'AWS Certified DevOps Engineer',
      },
    ],
    swipperimages : [
      { src: '../assets/awsSolutions/amazonAurora.jpg', caption: 'Amazon Aurora and RDS' },
      { src:'../assets/awsSolutions/amazoneks.png', caption: 'Amazon EKS' },
      { src: '../assets/awsSolutions/cloudfront.png',caption: 'Amazon CloudFront' },
      { src:'../assets/awsSolutions/codepipeline.png',caption: 'Amazon Codepipeline' },
      { src: '../assets/awsSolutions/ec2.avif', caption: 'Amazon EC2' },
      { src:'../assets/awsSolutions/lambda.jpeg',caption: 'Amzon Lambda' },
      { src: '../assets/awsSolutions/stepfunctions.svg', caption: 'Amazon Step Functions' },
     
    ],
  };

  

  return (
    <section>
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
      <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      
<DetailsSection images={service.images} explanation={service.explanation}/>
      {/* New Section with Background Image and Button */}

      <ServicesSwiper swipperimages={service.swipperimages}/>

<Promo/>


      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default AWSServicePage;
