import React from 'react';

import './css/ServicesPage.css';
import FooterCertificates from '../../components/FooterCertificates';
import ImageSlider from '../../components/ImageSlider';

import ServicesSwiper from '../../components/ServicesSwiper';
import Promo from '../../components/Promo';
import DetailsSection from '../../components/ServiceDetails';

const TestingServicePage = () => {
  const service = {
    name: 'Software Testing Services',
    description: 'Our software testing services ensure your products are free from bugs, secure, and optimized for performance, providing a seamless experience for users.',

    images: [
      '../assets/testing/testing.webp',
      '../assets/testing/testing2.png',
      '../assets/testing/testing3.jpg',
      '../assets/testing/testing1.jpeg',
      '../assets/testing/testing4.png',
   
     
   
     
   
      
    ],
    explanation: [
   
     "Comprehensive manual and automation testing services for flawless performance.",
      "End-to-end functional testing to ensure application reliability.",
      "Automation frameworks designed for scalability and speed.",
      "Seamless integration of automated tests into CI/CD pipelines.",
      "User experience testing for enhanced usability and accessibility.",
      "Regression testing to ensure stability after updates or changes.",
      "Cross-platform and cross-browser testing for consistent functionality.",
      "Performance and load testing to ensure application resilience under stress.",
      "Detailed bug tracking and reporting for swift issue resolution.",
      "24/7 testing support for continuous quality assurance."
    ],
    quotes: [
      '',
      'Ensuring your software works perfectly, every time.',
      '',
      'Your quality assurance partner for seamless software experiences.',
     
    ],
    certificatesData: [
      {
        src: '../assets/certificates/selenium.png',
        alt: 'Azure DevOps Engineer',
      },
      {
        src: '../assets/certificates/csqa.png',
        alt: 'Azure Fundamentals',
      },
      {
        src: '../assets/certificates/cstm.png',
        alt: 'Azure Solutions Architect',
      },
    ],
    swipperimages : [
      { src: '../assets/testingSolutions/accuracy.jpg', caption: 'Accuracy' },
      { src:'../assets/testingSolutions/automation.jpg', caption: 'Automation testing' },
      { src: '../assets/testingSolutions/coverage.png',caption: 'Coverage' },
      { src:'../assets/testingSolutions/debugging.avif',caption: 'Test debugging' },
      { src: '../assets/testingSolutions/efficiency.png', caption: 'Effeciency' },
      { src:'../assets/testingSolutions/integration.jpeg',caption: 'Integration' },
      { src: '../assets/testingSolutions/regression.webp', caption: 'Regression' },
      { src: '../assets/testingSolutions/reliability.jpg',caption: 'Reliability' },
      { src: '../assets/testingSolutions/scalability.png', caption: 'Scalability' },
      { src: '../assets/testingSolutions/validated.jpg', caption: 'Validity' },
    ],
  };

  

  return (
    <section>
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
        <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      <DetailsSection images={service.images} explanation={service.explanation} />

      {/* Services Swiper Section */}
      <ServicesSwiper swipperimages={service.swipperimages}/>

      {/* Promo Section */}
      <Promo />

      {/* Footer Certificates Section */}
      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default TestingServicePage;
