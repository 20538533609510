import React from 'react';
import './css/ManagementHomePage.css'; 

const Home = ({ userRole, access }) => {
  return (
    <div className="home-container">
      <h1 className="home-title">Welcome to the Dashboard</h1>
      <p className="home-description">
        Manage job postings, review contact form submissions, and more through this intuitive interface. Stay organized, stay efficient.
      </p>
    </div>
 
  );
};

export default Home;
