import React from 'react';

import './css/ServicesPage.css';
import FooterCertificates from '../../components/FooterCertificates';
import ImageSlider from '../../components/ImageSlider';

import ServicesSwiper from '../../components/ServicesSwiper';
import Promo from '../../components/Promo';
import DetailsSection from '../../components/ServiceDetails';

const MarketingServicesPage= () => {
  const service = {
    name: 'Digital Marketing',
    description: 'Comprehensive digital marketing strategies to enhance your brand visibility and drive growth.',
    images: [
      '../assets/marketing/dm3.png',
      '../assets/marketing/dm1.jpg',
      '../assets/marketing/dm2.png',
  
      '../assets/marketing/dm4.jpg',
      '../assets/marketing/dm5.png',
    ],
    explanation: [
      // digital marketing services page:

"Data-driven digital marketing strategies to enhance brand visibility and drive engagement.",
"Customized marketing campaigns tailored to your target audience across multiple platforms.",
"Effective SEO techniques and content marketing strategies to achieve organic growth and improve search rankings.",
"Engaging social media marketing to build brand loyalty and connect with customers in real-time.",
"Email marketing campaigns designed for lead nurturing and customer retention.",
"Pay-per-click (PPC) advertising to maximize ROI and drive targeted traffic to your website.",
"Analytics and reporting to track performance metrics and inform data-driven decisions.",
"Continuous campaign optimization based on insights to enhance overall marketing effectiveness."
      
    ],
    quotes: [
      '',
      'Connect with your audience through tailored marketing solutions.',
      '',
      '',
      '',
    ],
    certificatesData: [
      {
        src: '../assets/certificates/google_analytics.jpeg',
        alt: 'Google Analytics Certification',
      },
      {
        src: '../assets/certificates/facebook_blueprint.png',
        alt: 'Facebook Blueprint Certification',
      },
      {
        src: '../assets/certificates/googleads.png',
        alt: 'Google ads Certification',
      },
    ],
    swipperimages : [
      { src: '../assets/marketingSolutions/analytics.webp', caption: 'Marketing Analytics' },
      { src:'../assets/marketingSolutions/content.png', caption: 'Content Marketing' },
      { src: '../assets/marketingSolutions/conversion.jpeg',caption: 'Conversion Marketing ' },
      { src:'../assets/marketingSolutions/engagement.png',caption: 'Engagement Marketing' },
      { src: '../assets/marketingSolutions/lead.png', caption: 'Lead Marketing' },
      { src:'../assets/marketingSolutions/optimised.png',caption: 'Optimised Marketing' },
      { src: '../assets/marketingSolutions/seo.jpg', caption: 'SEO' },
      { src: '../assets/marketingSolutions/strategy.jpg',caption: 'Marketing Strategy' },
      { src: '../assets/marketingSolutions/traffic.avif', caption: 'Traffic' },

    ],
  };

  return (
    <section>
      {/* Top Section with Background Image and Swiper */}
      <div className="serviceHeader">
        <ImageSlider images={service.images} quotes={service.quotes} />
      </div>

      {/* Details Section */}
      <DetailsSection images={service.images} explanation={service.explanation}/>

      {/* New Section with Background Image and Button */}
      <ServicesSwiper swipperimages={service.swipperimages}/>

      <Promo/>

      {service.certificatesData.length > 0 && (
        <FooterCertificates certificates={service.certificatesData} />
      )}
    </section>
  );
};

export default MarketingServicesPage;