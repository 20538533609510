import React, { useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import './css/Dashboard.css'; 
import { FaHome, FaUser, FaBriefcase, FaEnvelope, FaSignOutAlt, FaGraduationCap, FaPenAlt } from 'react-icons/fa';
import JobManagementPage from './ManagementJobPage';
import Home from './ManagementHomePage';
import ContactPage from './ManagementContactPage';
import Courses from './ManagementCoursePage';
import LoginPopup from './LoginPopup';
import UserRolesPage from './ManagementUserRolesPage';
import PostBlogPage from './ManagementBlogs';
import { verifyToken } from '../../services/api'; 
import Loader from '../../components/Loader'; 

const Dashboard = () => {
  const [activeComponent, setActiveComponent] = useState('home');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userRole, setUserRole] = useState(null);
  const [access, setAccess] = useState({});
  const [loading, setLoading] = useState(true); // Loader state
  const [errorMessage, setErrorMessage] = useState(''); // Error state

  // Function to verify the token and handle user state
  const authenticateUser = async () => {
    const token = Cookies.get('authToken');
    if (token) {
      try {
        const response = await verifyToken(token); // API call to verify token
        if (response.success) {
          setIsLoggedIn(true); 
          setUserRole(response.user.role); // Get the user role
          setAccess(response.user.access || {}); // Get the access permissions
        } else {
          setIsLoggedIn(false);
          handleLogout(); // Log out on invalid token
        }
      } catch (error) {
        console.error('Error verifying token:', error);
        setErrorMessage('Error verifying token. Please try again.'); 
        handleLogout(); 
      } finally {
        setLoading(false); // Stop loader once process completes
      }
    } else {
      setLoading(false); // Stop loader if no token found
    }
  };

  // Verify token once after login (when the component mounts)
  useEffect(() => {
    authenticateUser();
  },[]); 

  const hasAccess = (page, permissionType = 'view') => {
    // Admin users have access to all pages
    if (userRole === 'admin') return true;

    // Editor and Viewer access logic
    if (userRole === 'editor') {
      return page !== 'User'; // Editors can't access the Users & Roles page
    }
    if (userRole === 'viewer') {
      return page !== 'User'; 
    }


    return access[page] && access[page][permissionType];
  };

  const handleLogout = () => {
    Cookies.remove('authToken');
    setIsLoggedIn(false);
  };

  const renderComponent = () => {
    const commonProps = { userRole, access };
    switch (activeComponent) {
      case 'home':
        return <Home {...commonProps} />;
      case 'jobs':
        return <JobManagementPage {...commonProps} />;
      case 'contact':
        return <ContactPage {...commonProps} />;
      case 'courses':
        return <Courses {...commonProps} />;
      case 'user':
        return <UserRolesPage {...commonProps} />;
        case 'Blogs':
        return <PostBlogPage {...commonProps} />;
      default:
        return <Home {...commonProps} />;
    }
  };
  if (loading) {
    return <Loader />; // Display loader while loading
  }

  if (!isLoggedIn) {
    return <LoginPopup onLogin={setIsLoggedIn} />;
  }

  return (
    <div className="dashboard">
      {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Show error if any */}

      <div className="sidebar">
        {hasAccess('Home') && (
          <div className="sidebar-item" onClick={() => setActiveComponent('home')}>
            <FaHome className="icon" />
            <span>Home</span>
          </div>
        )}
        {hasAccess('Jobs') && (
          <div className="sidebar-item" onClick={() => setActiveComponent('jobs')}>
            <FaBriefcase className="icon" />
            <span>Jobs</span>
          </div>
        )}
        {hasAccess('Blogs') && (
          <div className="sidebar-item" onClick={() => setActiveComponent('Blogs')}>
            <FaPenAlt className="icon" />
            <span>Blogs</span>
          </div>
        )}
        {hasAccess('Contact') && (
          <div className="sidebar-item" onClick={() => setActiveComponent('contact')}>
            <FaEnvelope className="icon" />
            <span>Contact</span>
          </div>
        )}
        {hasAccess('Courses') && (
          <div className="sidebar-item" onClick={() => setActiveComponent('courses')}>
            <FaGraduationCap className="icon" />
            <span>Courses</span>
          </div>
        )}
        {userRole === 'admin' || hasAccess('User') ? (
          <div className="sidebar-item" onClick={() => setActiveComponent('user')}>
            <FaUser className="icon" />
            <span>Users & Roles</span>
          </div>
        ) : null}
        <div className="sidebar-item" onClick={handleLogout}>
          <FaSignOutAlt className="icon" />
          <span>Logout</span>
        </div>
      </div>

      <div className="main-content">
        {/* Render components dynamically */}
        {renderComponent()}
        {!hasAccess(activeComponent, 'edit') && (
          <div className="view-only-message">You have view-only access to this page.</div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
