import React, { useState, useEffect } from 'react';
import './css/Careers.css'; // Import CSS for styles
import {  FaBriefcase, FaMapMarkerAlt } from 'react-icons/fa';
import Swal from 'sweetalert2'; // Import SweetAlert2

import { ApplyJob, fetchJobs } from '../services/api';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/Loader';
const whyVijprat = [
  {
    title: 'Flexible Working Hours',
    description: 'We provide flexible working hours to all our employees for creating a better work/life balance & job satisfaction. You can choose your own working hours, after receiving the required consent.',
    image: '../assets/why1.gif',
  },
  {
    title: 'Work From Anywhere',
    description: 'We follow a hybrid work culture, our employees have the freedom to work from any part of the world and coming every day to the office is never mandatory for us. We believe in freedom of work.',
    image: '../assets/why2.gif',
  },
  {
    title: 'Certification Reimbursement',
    description: 'At Vijprat, we nurture and promote a learning environment. We offer different certifications for our employees to enhance and upgrade their skills. Also, we will reimburse for the certificates that were not offered by us.',
    image: '../assets/why3.gif',
  },
  

];

const CareersPage = () => {
  const navigate = useNavigate();
  const [jobOpenings, setJobOpenings] = useState([]);
  const [selectedJob, setSelectedJob] = useState(null);
  const [showJobPopup, setShowJobPopup] = useState(false);
  const [showApplicationPopup, setShowApplicationPopup] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [error, setError] = useState(null); 

  useEffect(() => {
    const fetchJobOpenings = async () => {
      setLoading(true); // Start loading
      setError(null);   // Reset error state

      try {
        const response = await fetchJobs();
        setJobOpenings(response);
      } catch (error) {
        setError('Error fetching job openings. Please try again later.');
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchJobOpenings();
  }, []);

  const handleApplyNow = (job) => {
    setSelectedJob(job);
    setShowJobPopup(true);
  };

  const handleOpenApplicationForm = () => {
    setShowJobPopup(false);
    setShowApplicationPopup(true);
  };

  const handleSubmitApplication = async (e) => {
    e.preventDefault();
    const jobId = selectedJob._id;
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;
    const resume = e.target.resume.value;

    const formData = new FormData();
    formData.append('jobId', jobId);
    formData.append('applicantName', name);
    formData.append('applicantEmail', email);
    formData.append('applicantPhone', phone);
    formData.append('resumeUrl', resume);

    setLoading(true); 
    try {
      await ApplyJob({
        jobId,
        applicantName: name,
        applicantEmail: email,
        applicantPhone: phone,
        resumeUrl: resume,
      });
      setShowApplicationPopup(false);
      Swal.fire('Success', 'Your application has been successfully sent!', 'success');
    } catch (error) {
      Swal.fire('Error', `There was a problem submitting your application: ${error.response ? error.response.data.message : error.message}`, 'error');
    } finally {
      setLoading(false); // Stop loading after submission
    }
  };

  return (
    <div className="careers-page">
      <div className="fixed-background-section"></div>

      {/* Current Openings Section */}
      <section data-aos="fade-right" className="openings-section">
        <h2 className="section-heading">CURRENT OPENINGS</h2>
        <p className="section-description">
          We are looking for some super enthusiastic & passionate individuals, who believe in continuous progress.
        </p>
        
        {loading ? (
          <Loader /> // Show loader while data is loading
        ) : error ? (
          <p className="error-message">{error}</p> // Show error message if something went wrong
        ) : jobOpenings.length > 0 ? (
          <div className="job-cards">
            {jobOpenings.map((job, index) => (
              <div data-aos="zoom-in" className="job-card" key={index}>
                {job.icon}
                <h3>{job.title}</h3>
                <div className="job-details">
                  <p><FaBriefcase /> {job.experience}</p>
                  <p><FaMapMarkerAlt /> {job.location}</p>
                </div>
                <div className="hover-button">
                  <button onClick={() => handleApplyNow(job)}>Apply Now</button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No Openings Available</p>
        )}
      </section>

      {/* Didn't Find Anything Section */}
      <section className="no-job-section">
        <h2>Didn't Find Anything?</h2>
        <p>If you think there is nothing for you here, then let us tailor something for you</p>
        <button className="resume-button" onClick={() => navigate('/contact-us')}>Contact Us</button>
      </section>

      {/* Why Vijprat Section */}
      <section data-aos="fade-right" className="why-section">
        <h2>Why Vijprat?</h2>
        <p>Because we understand what really matters for our people</p>
        <div className="why-cards">
          {whyVijprat.map((item, index) => (
            <div data-aos="zoom-out" className={`why-card ${index % 2 === 1 ? 'reverse' : ''}`} key={index}>
              <div className="why-text">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
              <div className="why-image">
                <img src={item.image} alt={`Why Vijprat ${index + 1}`} />
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Job Description Popup */}
      {showJobPopup && (
        <div data-aos="fade-up" className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={() => setShowJobPopup(false)}>X</button>
            <h3>{selectedJob.title}</h3>
            <div className='popup-job-description'>{selectedJob.description}</div>
            <button onClick={handleOpenApplicationForm}>Fill Application</button>
          </div>
        </div>
      )}

      {/* Application Form Popup */}
      {showApplicationPopup && (
  <div data-aos="fade-up" className="popup-overlay">
    <div className="popup">
      <button className="close-button" onClick={() => setShowApplicationPopup(false)}>X</button>
      <h3>Application Form</h3>
      {loading ? (  // Show the loader if form is submitting
        <Loader /> 
      ) : (
        <form onSubmit={handleSubmitApplication}>
          <div>
            <label>Name:</label>
            <input type="text" name="name" required disabled={loading} />
          </div>
          <div>
            <label>Email:</label>
            <input type="email" name="email" required disabled={loading} />
          </div>
          <div>
            <label>Phone:</label>
            <input type="tel" name="phone" required disabled={loading} />
          </div>
          <div>
            <label>Resume (URL):</label>
            <input type="url" name="resume" required disabled={loading} />
          </div>
          <button type="submit" disabled={loading}>
            Submit Application
          </button>
        </form>
      )}
    </div>
  </div>
)}
    </div>
  );
};

export default CareersPage;
