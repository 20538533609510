import React, { useState, useEffect } from 'react';
import '../css/Careers.css';
import { FaMapMarkerAlt, FaCalendarAlt, FaMoneyBillAlt, FaClock } from 'react-icons/fa';
import Swal from 'sweetalert2';
import { ApplyCourse, fetchCourses } from '../../services/api';
import Loader from '../../components/Loader';
import { useNavigate } from 'react-router-dom';

const CoursesServicePage = () => {
  const navigate=useNavigate();
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showCoursePopup, setShowCoursePopup] = useState(false);
  const [showApplicationPopup, setShowApplicationPopup] = useState(false);
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState(null); 
 

  useEffect(() => {
    const fetchCourse = async () => {
      setLoading(true);
      setError(null);   
      try {
        const response = await fetchCourses();
        setCourses(response);
      } catch (error) {
        setError('Error fetching training programs. Please try again later.');
      } finally {
        setLoading(false); 
      }
    };
    fetchCourse();
  }, []);

  const handleApplyNow = (course) => {
    setSelectedCourse(course);
    setShowCoursePopup(true);
  };

  const handleOpenApplicationForm = () => {
    setShowCoursePopup(false);
    setShowApplicationPopup(true);
  };

  

  const handleSubmitApplication = async (e) => {
    e.preventDefault();
    const courseId = selectedCourse?._id || null;
    const name = e.target.name.value;
    const email = e.target.email.value;
    const phone = e.target.phone.value;

    setLoading(true); // Start form loading when submitting
   

    try {
      await ApplyCourse({
        courseId,
        applicantName: name,
        applicantEmail: email,
        applicantPhone: phone,
      });
      setShowApplicationPopup(false);
      Swal.fire('Success', 'Your application has been successfully sent!', 'success');
    } catch (error) {
      setError(`There was a problem submitting your application: ${error.message}`);
    } finally {
     
      setLoading(false); 
      // setFormError(null)
    }
  };

  return (
    <div className="courses-page">
      <div className="fixed-background-section">
        <div data-aos="fade-right" className="fixed-text">
          <h1>A new path to learning</h1>
          <p>Explore our latest training programs to kickstart or upgrade your career!</p>
        </div>
      </div>

      {/* Current Courses Section */}
      <section data-aos="fade-right" className="openings-section">
        <h3>AVAILABLE TRAINING PROGRAMS</h3>
        <p className="section-description">
          We offer a variety of training programs to help you excel in your field. Find the one that fits your goals!
        </p>

        {loading ? (
          <Loader /> // Show loader while data is loading
        ) : error ? (
          <p className="error-message">{error}</p> // Show error message if something went wrong
        ) : courses.length > 0 ? (
          <div className="job-cards">
            {courses.map((course, index) => (
              <div data-aos="zoom-in" className="job-card" key={index}>
                <h3>{course.CourseName}</h3>
                <div className="job-details">
                  <p><FaMapMarkerAlt /> {course.location}</p>
                  <p><FaCalendarAlt /> Start Date: {new Date(course.startDate).toLocaleDateString()}</p>
                  <p><FaMoneyBillAlt /> Fees: ₹{course.fees}</p>
                  <p><FaClock /> Duration: {course.duration}</p>
                </div>
                <div className="hover-button">
                  <button onClick={() => handleApplyNow(course)}>Apply Now</button>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p>No Training Programs Available</p>
        )}
      </section>

      {/* Didn't Find Anything Section */}
      <section className="no-job-section">
        <h2>Didn't Find Anything?</h2>
        <p>If you think there is nothing for you here, then let us tailor something for you</p>
        <button className="resume-button" onClick={() => navigate('/contact-us')}>Contact us</button>
      </section>

      {/* Course Description Popup */}
      {showCoursePopup && (
        <div data-aos="fade-up" className="popup-overlay">
          <div className="popup">
            <button className="close-button" onClick={() => setShowCoursePopup(false)}>X</button>
            <h3>{selectedCourse.CourseName}</h3>
            <div className="popup-course-description">{selectedCourse.description}</div>
            <h4>Technologies Covered:</h4>
            <ul>
              {selectedCourse.techStacks.map((stack, idx) => (
                <li key={idx}>
                  <strong>{stack.tech}:</strong> {stack.topics.join(', ')}
                </li>
              ))}
            </ul>
            <button onClick={handleOpenApplicationForm}>Fill Application</button>
          </div>
        </div>
      )}

      {/* Application Form Popup */}
      {showApplicationPopup && (
  <div data-aos="fade-up" className="popup-overlay">
    <div className="popup">
      <button className="close-button" onClick={() => setShowApplicationPopup(false)}>X</button>
      <h3>Application Form</h3>
      {loading ? (  // Show the loader if form is submitting
        <Loader /> 
      ) : (
        <form onSubmit={handleSubmitApplication}>
          <div>
            <label>Name:</label>
            <input type="text" name="name" required disabled={loading} />
          </div>
          <div>
            <label>Email:</label>
            <input type="email" name="email" required disabled={loading} />
          </div>
          <div>
            <label>Phone:</label>
            <input type="tel" name="phone" required disabled={loading} />
          </div>
          
          <button type="submit" disabled={loading}>
            Submit Application
          </button>
        </form>
      )}
    </div>
  </div>
)}
    </div>
  );
};

export default CoursesServicePage;
