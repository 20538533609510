import React from 'react';

import './css/ServicesPage.css';
import FooterCertificates from '../../components/FooterCertificates';
import ImageSlider from '../../components/ImageSlider';

import ServicesSwiper from '../../components/ServicesSwiper';
import Promo from '../../components/Promo';
import DetailsSection from '../../components/ServiceDetails';

const SFServicePage = () => {
  const service = {
    
      name: 'Salesforce',
      description: 'Salesforce provides a comprehensive set of cloud services...',
      images: [
        '../assets/salesforce/sf3.jpg',
        '../assets/salesforce/sf1.jpg',
        // '../assets/salesforce/sf2.webp',
     
        // '../assets/salesforce/sf4.png',
        '../assets/salesforce/sf5.jpg',
      ],
      explanation: [
        "Boost productivity and collaboration with Salesforce’s all-in-one CRM platform.",
"Tailored solutions for sales, marketing, and customer service, all integrated in Salesforce.",
"Optimize customer engagement with Salesforce’s AI-powered tools like Einstein Analytics.",
"Flexible and scalable pricing plans to match your business needs on Salesforce.",
"Enterprise-level security and data protection with Salesforce’s robust security features.",
"Seamless integration with third-party applications for a unified business ecosystem.",
"Cloud-based platform ensuring high availability, scalability, and performance.",
"24/7 monitoring and expert support for uninterrupted Salesforce operations."
      ],
      quotes: [
        
        '',
        'Empower your customer relationships with Salesforce.',
        '',
       
      ],
      certificatesData :[
        {
          src: '../assets/certificates/salesforce-Administrator.png',
          alt: 'AWS Security Specialty',
        },
        {
          src: '../assets/certificates/salesforce-Associate.png',
          alt: 'AWS Certified Solutions Architect',
        },
        {
          src: '../assets/certificates/salesforce-datacloudconsultant.webp',
          alt: 'AWS Certified DevOps Engineer',
        },
       
      ],
      swipperimages : [
        { src: '../assets/salesforceSolutions/consumergoodscloud.png', caption: 'Consumer goods cloud' },
        { src:'../assets/salesforceSolutions/experiencecloud.png', caption: 'Experience cloud' },
        { src: '../assets/salesforceSolutions/financialservicescloud.png',caption: 'Financial Services cloud' },
        { src:'../assets/salesforceSolutions/healthcloud.png',caption: 'Health Cloud' },
        { src: '../assets/salesforceSolutions/omnistudio.png', caption: 'Omni Studio' },
        { src:'../assets/salesforceSolutions/salescloud.png',caption: 'Sales Cloud' },
        { src: '../assets/salesforceSolutions/servicecloud.png', caption: 'Service Cloud' },
        
      ],
    
  };


  return (
    <section>
    {/* Top Section with Background Image and Swiper */}
    <div className="serviceHeader">
    <ImageSlider images={service.images} quotes={service.quotes} />
    </div>

    {/* Details Section */}
    <DetailsSection images={service.images} explanation={service.explanation}/>

    {/* New Section with Background Image and Button */}

    <ServicesSwiper swipperimages={service.swipperimages}/>

<Promo/>


    {service.certificatesData.length > 0 && (
      <FooterCertificates certificates={service.certificatesData} />
    )}
  </section>
  );
};

export default SFServicePage;
