import React from 'react';
import { Link } from 'react-router-dom';
import styles from './css/NotFoundPage.module.css';

const NotFoundPage = () => {
  return (
    <div className={styles.notFoundContainer}>
      <div data-aos="fade-right"  className={styles.content}>
        <h1 data-aos="fade-up"  className={styles.errorCode}>404</h1>
        <h2 data-aos="fade-up"  className={styles.title}>Oops! Page not found</h2>
        <p className={styles.description}>
          The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.
        </p>
        <Link to="/" className={styles.homeButton}>Go Back to Home</Link>
      </div>
    </div>
  );
};

export default NotFoundPage;
