import React, { useState } from 'react';
import './css/ContactForm.css';
import { SubmitContact } from '../services/api';
import Loader from './Loader';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading when submitting the form

    try {
      await SubmitContact(formData);
      setSubmitted(true); // Set submitted to true when successfully sent
    } catch (err) {
      setError('Error sending message, please try again later.');
    } finally {
      setLoading(false); // Stop loading after submission
    }
  };

  return (
    <div data-aos="flip-left" className="contact-form">
      <h2>Get in touch</h2>
      {submitted ? (
        <>
          <img src="../assets/check.png" alt="Success" />
          <p>Thank you! Your message has been sent.</p>
        </>
      ) : (
        <>
          {loading ? (
            <Loader /> // Show loader while loading
          ) : (
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <input
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
                <input
                  type="text"
                  name="phone"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-row">
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-row">
                <textarea
                  name="message"
                  placeholder="Message"
                  rows="4"
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </div>
              {error && <p className="error-message">{error}</p>}
              <button type="submit" className="submit-btn">
                Submit
              </button>
            </form>
          )}
        </>
      )}
    </div>
  );
};

export default ContactForm;
