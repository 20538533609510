import React from 'react';

const CourseForm = ({ formValues, setFormValues, techStacks, setShowTechStackPopup, handlePostCourse }) => {
  return (
    <form onSubmit={handlePostCourse} className="post-job-form">
      <h2>Add a New Course</h2>
      <div>
        <label>Course Name:</label>
        <input
          type="text"
          value={formValues.CourseName}
          onChange={(e) => setFormValues({ ...formValues, CourseName: e.target.value })}
          required
        />
      </div>
      <div>
        <label>Duration:</label>
        <input
          type="text"
          value={formValues.duration}
          onChange={(e) => setFormValues({ ...formValues, duration: e.target.value })}
          required
        />
      </div>
      <div>
        <label>Location:</label>
        <input
          type="text"
          value={formValues.location}
          onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
          required
        />
      </div>
      <div>
        <label>Fees:</label>
        <input
          type="number"
          value={formValues.fees}
          onChange={(e) => setFormValues({ ...formValues, fees: e.target.value })}
          required
        />
      </div>
      <div>
        <label>Start Date:</label>
        <input
          type="date"
          value={formValues.startDate}
          onChange={(e) => setFormValues({ ...formValues, startDate: e.target.value })}
          required
        />
      </div>
      <div>
        <label>Description:</label>
        <textarea
          value={formValues.description}
          onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
          required
        />
      </div>
      <button type="button" onClick={() => setShowTechStackPopup(true)}>
        Add Tech Stack
      </button>
      <br/>
      <button type="submit">Post Course</button>
    </form>
  );
};

export default CourseForm;
