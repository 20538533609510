import React, { useEffect, useState } from 'react';
import './css/ManagementContactPage.css'; 
import { fetchContactForms, deleteContactForm } from '../../services/api'; // API service
import Loader from '../../components/Loader'; 
import Swal from 'sweetalert2'; // Import SweetAlert2

const ContactPage = ({ userRole, access }) => {
  const [contactForms, setContactForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const loadContactForms = async () => {
      try {
        const data = await fetchContactForms();
        setContactForms(data);
      } catch (error) {
        setErrorMessage('Error fetching contact form details.');
        console.log('Error fetching contact form details:', error);
      } finally {
        setLoading(false);
      }
    };

    loadContactForms();
  }, []);

  // Function to handle delete with SweetAlert2 confirmation
  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
    });

    if (result.isConfirmed) {
      setLoading(true); // Show loader during deletion
      try {
        await deleteContactForm(id); // Call API to delete the form
        setContactForms(contactForms.filter((form) => form._id !== id)); // Update state by removing deleted form

        Swal.fire(
          'Deleted!',
          'The contact form has been deleted.',
          'success'
        );
      } catch (error) {
        console.log('Error deleting contact form:', error);
        Swal.fire(
          'Error!',
          'There was an error deleting the contact form.',
          'error'
        );
      } finally {
        setLoading(false); // Hide loader after deletion
      }
    }
  };

  if (loading) {
    return <Loader />; // Display loader while data is being fetched or deleted
  }

  return (
    <div className="contact-page">
      <h1 className="contact-title">Contact Form Submissions</h1>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      
      <div className="contact-list">
        {contactForms.map((form) => (
          <div key={form._id} className="contact-card">
            <h3>{form.name}</h3>
            <p>Email: {form.email}</p>
            <p>Phone: {form.phone}</p>
            <p>Message: {form.message}</p>
            <button
              className="delete-btn"
              onClick={() => handleDelete(form._id)}
            >
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactPage;
