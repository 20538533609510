import React from 'react';
import CountUp from 'react-countup';
import './css/Stats.css';

const Stats = () => {
  const stats = [
    { end: 2024, label: 'The Year of Foundation', separator: ''},
    { end: 120, suffix: '+', label: 'Open Source Utilities', separator: ',' },
    { end: 280, suffix: '+', label: 'Professionals and Growing', separator: ',' },
    { end: 90, suffix: '%', label: 'Repeat Business', separator: ',' },
  ];

  return (
    <section className="stats-section">
      <div className="stats-container">
        {stats.map((stat, index) => (
          <div data-aos="zoom-in" key={index} className="stat-card">
            <h2 className="stat-number">
              <CountUp 
                end={stat.end} 
                duration={2.0} 
                suffix={stat.suffix || ''} 
                separator={stat.separator || ''} 
              />
            </h2>
            <p className="stat-label">{stat.label}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Stats;
