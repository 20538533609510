import React from 'react';

const UpdateCoursePopup = ({ formValues, setFormValues, techStacks, handleSubmitUpdate, setShowUpdatePopup }) => {
  return (
    <div className="popup-overlay">
      <div className="popup">
        <button className="close-button" onClick={() => setShowUpdatePopup(false)}>X</button>
        <h3>Update Course</h3>
        <form onSubmit={handleSubmitUpdate}>
          <div>
            <label>Course Name:</label>
            <input
              type="text"
              value={formValues.CourseName}
              onChange={(e) => setFormValues({ ...formValues, CourseName: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Duration:</label>
            <input
              type="text"
              value={formValues.duration}
              onChange={(e) => setFormValues({ ...formValues, duration: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Location:</label>
            <input
              type="text"
              value={formValues.location}
              onChange={(e) => setFormValues({ ...formValues, location: e.target.value })}
              required
            />
          </div>
          <div>
            <label>Fees:</label>
            <input
              type="number"
              value={formValues.fees}
              onChange={(e) => setFormValues({ ...formValues, fees: e.target.value })}
              required
            />
          </div>
          <div>
          <label>Start Date:</label>
          <input
            type="date"
            value={formValues.startDate}
            onChange={(e) => setFormValues({ ...formValues, startDate: e.target.value })}
            required
          />
        </div>
        <div>
          <label>Description:</label>
          <textarea
            value={formValues.description}
            onChange={(e) => setFormValues({ ...formValues, description: e.target.value })}
            required
          />
        </div>

        {/* Render TechStacks */}
        <div>
          <label>Technology Stacks:</label>
          {/* Check if techStacks exists, else render an empty array */}
          {(formValues.techStacks || []).map((stack, index) => (
            <div key={index} className="tech-stack">
              <div>
                <label>Tech {index + 1}:</label>
                <input
                  type="text"
                  value={stack.tech}
                  onChange={(e) => {
                    const updatedTechStacks = [...formValues.techStacks];
                    updatedTechStacks[index].tech = e.target.value;
                    setFormValues({ ...formValues, techStacks: updatedTechStacks });
                  }}
                  required
                />
              </div>
              <div>
                <label>Topics (comma-separated):</label>
                <input
                  type="text"
                  value={stack.topics.join(', ')} // Convert array back to comma-separated string
                  onChange={(e) => {
                    const updatedTechStacks = [...formValues.techStacks];
                    updatedTechStacks[index].topics = e.target.value.split(',').map(topic => topic.trim());
                    setFormValues({ ...formValues, techStacks: updatedTechStacks });
                  }}
                  required
                />
              </div>
            </div>
          ))}
        </div>
          <button type="submit">Update Course</button>
        </form>
      </div>
    </div>
  );
};

export default UpdateCoursePopup;
