// import axios from 'axios';
import Cookies from 'js-cookie';

const apiUrl = '/api'; // Replace with your actual API base URL
// https://vijprat-dev.onrender.com/api
const getAuthToken = () => Cookies.get('authToken');

const fetchData = async (url, options = {}) => {
  const token = getAuthToken();

  const headers = {
    'Content-Type': 'application/json',
    ...options.headers,
  };

  if (token) {
    headers['Authorization'] = `${token}`;
  }

  const response = await fetch(`${apiUrl}${url}`, {
    ...options,
    headers,
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Something went wrong');
  }

  return response.json();
};

// Auth-related API call (for login)
export const login = async (email, password) => {
  return fetchData('/user/login', {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  });
};
// verify-token
export const verifyToken = async (token) => {
  try {
    return fetchData('/user/verify-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token }),
    });

  } catch (error) {
    console.log('Error verifying token:', error);
    throw error;
  }
};
// Fetch all courses
export const fetchCourses = async () => {
  return fetchData('/courses');
};

// Fetch applicants for a specific course
export const fetchApplicantsForCourse = async (courseId) => {
  return fetchData(`/courseapplications/${courseId}`);
};

// Post a new course
export const postCourse = async (courseData) => {
  return fetchData('/courses', {
    method: 'POST',
    body: JSON.stringify(courseData),
  });
};

// Delete a course
export const deleteCourse = async (courseId) => {
  return fetchData(`/courses/${courseId}`, {
    method: 'DELETE',
  });
};

// Update a course
export const updateCourse = async (courseId, updatedData) => {
  return fetchData(`/courses/${courseId}`, {
    method: 'PUT',
    body: JSON.stringify(updatedData),
  });
};

// Fetch all jobs
export const fetchJobs = async () => {
  return fetchData('/jobs');
};

// Fetch applicants for a specific job
export const fetchApplicantsForJob = async (jobId) => {
  return fetchData(`/jobapplications/${jobId}`);
};

// Post a new job
export const postJob = async (jobData) => {
  return fetchData('/jobs', {
    method: 'POST',
    body: JSON.stringify(jobData),
  });
};

// Delete a job
export const deleteJob = async (jobId) => {
  return fetchData(`/jobs/${jobId}`, {
    method: 'DELETE',
  });
};

// Update a job
export const updateJob = async (jobId, updatedData) => {
  return fetchData(`/jobs/${jobId}`, {
    method: 'PUT',
    body: JSON.stringify(updatedData),
  });
};

// Fetch contact form submissions
export const fetchContactForms = async () => {
  return fetchData('/mail/submissions');
};

// Fetch all users
export const fetchUsers = async () => {
  return fetchData('/user'); // Assuming the endpoint is '/users'
};

// Register a new user
export const registerUser = async (userData) => {
  return fetchData('/user/register', {
    method: 'POST',
    body: JSON.stringify(userData),
  });
};
// Delete a user
export const deleteUser = async (userId) => {
  return fetchData(`/user/${userId}`, {
    method: 'DELETE',
  });
};
// create a new blog
export const createBlog = async (blogData) => {
  return fetchData('/blogs', {
    method: 'POST',
    body: JSON.stringify(blogData),
  });
};
//fetch all Blogs 
export const fetchBlog = async () => {
  return fetchData('/blogs'); //  the endpoint is '/blogs'
};
//fetch one Blog
export const fetchBlogid = async (id) => {
  return fetchData(`/blogs/${id}`); 
};
// , deleteBlog, updateBlog
export const deleteBlog = async (Id) => {
  return fetchData(`/blogs/${Id}`, {
    method: 'DELETE',
  });
};
export const updateBlog = async (Id, updatedData) => {
  return fetchData(`/blogs/${Id}`, {
    method: 'PUT',
    body: JSON.stringify(updatedData),
  });
};
// apply to job

export const ApplyJob = async (userData) => {
  return fetchData('/jobapplications/apply', {
    method: 'POST',
    body: JSON.stringify(userData),
  });
};

//Apply for course
export const ApplyCourse = async (userData) => {
  return fetchData('/courseapplications/apply', {
    method: 'POST',
    body: JSON.stringify(userData),
  });
};

// send contanct email
export const SubmitContact = async (userData) => {
  return fetchData('/mail/send', {
    method: 'POST',
    body: JSON.stringify(userData),
  });
};
// delete contact
export const deleteContactForm=async(userId)=>{
  return fetchData(`/mail/delete/${userId}`, {
    method: 'DELETE',
  });
}
// send contanct email
export const Subscribenewsletter = async (userData) => {
  return fetchData('/letter/subscribe', {
    method: 'POST',
    body: JSON.stringify(userData),
  });
};
// , deleteBlog, updateBlog