import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './css/JobManagementPage.css';
import {
  fetchCourses,
  fetchApplicantsForCourse,
  postCourse,
  deleteCourse,
  updateCourse,
} from '../../services/api';
import CourseForm from './components/CourseForm';
import CoursesList from './components/CoursesList';
import ApplicantsList from './components/ApplicantsList';
import UpdateCoursePopup from './components/UpdateCoursePopup';
import TechStackPopup from './components/TechStackPopup';


const CoursesPage = ({ userRole, access }) => {
  const [courses, setCourses] = useState([]);
  const [applicants, setApplicants] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [showUpdatePopup, setShowUpdatePopup] = useState(false);
  const [showTechStackPopup, setShowTechStackPopup] = useState(false);
  const [formValues, setFormValues] = useState({
    CourseName: '',
    duration: '',
    location: '',
    fees: 0,
    startDate: '',
    description: '',
    techStacks: [],
  });
  const [techStacks, setTechStacks] = useState([{ tech: '', topics: '' }]);
  const edit= userRole==='admin' ||userRole==="editor" || access?.Courses?.edit
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchCourses();
        setCourses(data);
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };
    fetchData();
  }, []);

  const handlePostCourse = async (e) => {
    e.preventDefault();
    try {
      await postCourse({ ...formValues, techStacks });
      Swal.fire('Success', 'Course posted successfully!', 'success');
      const data = await fetchCourses();
      setCourses(data);
    } catch (error) {
      console.error('Error posting course:', error);
    }
  };

  const handleDeleteCourse = async (courseId) => {
    try {
      await deleteCourse(courseId);
      Swal.fire('Success', 'Course deleted successfully!', 'success');
      const data = await fetchCourses();
      setCourses(data);
    } catch (error) {
      console.error('Error deleting course:', error);
    }
  };

  const handleUpdateCourse = (course) => {
    setFormValues({
      CourseName: course.CourseName,
      duration: course.duration,
      location: course.location,
      fees: course.fees,
      startDate: course.startDate,
      description: course.description,
      techStacks: course.techStacks,
    });
    setSelectedCourse(course);
    setShowUpdatePopup(true);
  };

  const handleSubmitUpdate = async (e) => {
    e.preventDefault();
    try {
      await updateCourse(selectedCourse._id, formValues);
      Swal.fire('Success', 'Course updated successfully!', 'success');
      const data = await fetchCourses();
      setCourses(data);
      setShowUpdatePopup(false);
    } catch (error) {
      console.error('Error updating course:', error);
    }
  };

  const handleCourseClick = async (course) => {
    setSelectedCourse(course);
    try {
      const data = await fetchApplicantsForCourse(course._id);
      setApplicants(data);
    } catch (error) {
      console.error('Error fetching applicants:', error);
    }
  };

  return (
    <div className="job-management-page">
    <h3>Courses</h3>
    {edit &&(
      <CourseForm
        formValues={formValues}
        setFormValues={setFormValues}
        techStacks={techStacks}
        setShowTechStackPopup={setShowTechStackPopup}
        handlePostCourse={handlePostCourse}
      />
    )}
      <div className="jobs-applicants-container">
        <CoursesList
         edit={edit}
          courses={courses}
          handleCourseClick={handleCourseClick}
          handleUpdateCourse={handleUpdateCourse}
          handleDeleteCourse={handleDeleteCourse}
        />
        <ApplicantsList
          applicants={applicants}
          selectedCourse={selectedCourse}
        />
      </div>
      {showUpdatePopup && (
        <UpdateCoursePopup
          formValues={formValues}
          setFormValues={setFormValues}
          techStacks={formValues.techStacks}
          handleSubmitUpdate={handleSubmitUpdate}
          setShowUpdatePopup={setShowUpdatePopup}
        />
      )}
      {showTechStackPopup && (
        <TechStackPopup
          techStacks={techStacks}
          setTechStacks={setTechStacks}
          setShowTechStackPopup={setShowTechStackPopup}
        />
      )}
    </div>
  );
};

export default CoursesPage;
