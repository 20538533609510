import React, { useState, useEffect } from 'react';
import { fetchUsers, registerUser, deleteUser } from '../../services/api';
import './css/UserRolesPage.css';
import Swal from 'sweetalert2';

const dashboardPages = ['Home', 'Jobs', 'Contact', 'Courses', 'User','Blogs'];

const UserRolesPage = ({ userRole, access }) => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ password: '', email: '', role: '' });
  const [roles] = useState(['editor', 'viewer', 'custom']);
  const [showCustomModal, setShowCustomModal] = useState(false); 
  const [customPermissions, setCustomPermissions] = useState({}); 
  const edit=userRole==='admin' ||userRole==="editor" || access?.Jobs?.edit 
  
  useEffect(() => {
    const getUsers = async () => {
      try {
        const fetchedUsers = await fetchUsers();
        if (Array.isArray(fetchedUsers)) {
          setUsers(fetchedUsers);
        } else {
          console.error('Fetched data is not an array:', fetchedUsers);
          setUsers([]);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    getUsers();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser({ ...newUser, [name]: value });
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    try {
      const userData = { ...newUser };
      if (newUser.role === 'custom') {
        userData.access = customPermissions;
      }
      await registerUser(userData);
      setUsers([...users, userData]);
      setNewUser({ password: '', email: '', role: '' });
      setCustomPermissions({}); 
      Swal.fire('Registered!', 'User has been registered successfully.', 'success');
    } catch (error) {
      Swal.fire('Error!', 'There was an issue registering the user.', 'error');
      console.error('Error adding user:', error);
    }
  };

  const handleDeleteUser = async (userId) => {
    try {
      await deleteUser(userId);
      setUsers(users.filter((user) => user._id !== userId));
      Swal.fire('Deleted!', 'User has been deleted successfully.', 'success');
    } catch (error) {
      console.error('Error deleting user:', error);
      Swal.fire('Error!', 'There was an issue deleting the user.', 'error');
    }
  };

  const handleCustomPermissionChange = (page, permissionType, checked) => {
    setCustomPermissions((prevPermissions) => {
      const newPermissions = {
        ...prevPermissions,
        [page]: { ...prevPermissions[page], [permissionType]: checked },
      };

      // If "Edit" is checked, also check "View" for that page
      if (permissionType === 'edit' && checked) {
        newPermissions[page] = { ...newPermissions[page], view: true };
      }

      return newPermissions;
    });
  };

  const renderCustomPermissionModal = () => (
    <div className="modal">
      <div className="modal-content">
   
          <h3>Custom Permissions</h3>
        
       
        <table>
          <thead>
            <tr>
              <th>Page</th>
              <th>View</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {dashboardPages.map((page) => (
              <tr key={page}>
                <td>{page}</td>
                <td>
                  <input
                    type="checkbox"
                    checked={customPermissions[page]?.view || false}
                    onChange={(e) => handleCustomPermissionChange(page, 'view', e.target.checked)}
                  />
                </td>
                <td>
                  <input
                    type="checkbox"
                    checked={customPermissions[page]?.edit || false}
                    onChange={(e) => handleCustomPermissionChange(page, 'edit', e.target.checked)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className="btn-ok" onClick={() => setShowCustomModal(false)}>Save</button>
      </div>
    </div>
  );

  return (
    <div className="user-roles-page">
      <h1>User Management</h1>

      {/* Hide the form if the user doesn't have 'edit' access to 'User' */}
      {edit  && (
        <form onSubmit={handleAddUser} className="form-container">
          <div className="form-group">
            <label>Email:</label>
            <input type="email" name="email" value={newUser.email} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>Password:</label>
            <input type="password" name="password" value={newUser.password} onChange={handleInputChange} required />
          </div>
          <div className="form-group">
            <label>Role:</label>
            <select
              name="role"
              value={newUser.role}
              onChange={(e) => {
                handleInputChange(e);
                if (e.target.value === 'custom') {
                  setShowCustomModal(true); // Show the custom permissions modal if "custom" is selected
                }
              }}
              required
            >
              <option value="">Select Role</option>
              {roles.map((role, idx) => (
                <option key={idx} value={role}>
                  {role.charAt(0).toUpperCase() + role.slice(1)}
                </option>
              ))}
            </select>
          </div>
          <button type="submit" className="btn-submit">
            Add User
          </button>
        </form>
      )}

      {showCustomModal && renderCustomPermissionModal()}

      <h2>Users</h2>
      <ul className="users-list">
        {Array.isArray(users) && users.length > 0 ? (
          users.map((user) => (
            <li key={user._id} className="user-item">
              {user.email} - {user.role.charAt(0).toUpperCase() + user.role.slice(1)}
              {/* Hide the delete button if the user doesn't have 'edit' access to 'User' */}
              {edit && (
                <button onClick={() => handleDeleteUser(user._id)} className="btn-delete">
                  Delete{}
                </button>
              )}
            </li>
          ))
        ) : (
          <li>No users found</li>
        )}
      </ul>
    </div>
  );
};

export default UserRolesPage;
