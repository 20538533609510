import React from 'react';

const TechStackPopup = ({ techStacks, setTechStacks, setShowTechStackPopup }) => {
  const handleAddTechStack = () => {
    setTechStacks([...techStacks, { tech: '', topics: '' }]);
  };

  const handleTechStackChange = (index, key, value) => {
    const updatedTechStacks = [...techStacks];
    updatedTechStacks[index][key] = value;
    setTechStacks(updatedTechStacks);
  };
  const handleSaveTechStack = () => {
    console.log(techStacks); // Save tech stacks to backend if needed
    // Swal.fire('Success', 'Tech stack saved!', 'success');
    setShowTechStackPopup(false);
  };
  return (
    <div className="popup-overlay">
      <div className="popup">
        <button className="close-button" onClick={() => setShowTechStackPopup(false)}>X</button>
        <h3>Add Tech Stacks</h3>
        <form>
              {techStacks.map((stack, index) => (
                <div key={index}>
                  <div>
                    <label>Tech Stack:</label>
                    <input
                      type="text"
                      value={stack.tech}
                      onChange={(e) => handleTechStackChange(index, 'tech', e.target.value)}
                      required
                    />
                  </div>
                  <div>
                    <label>Topics:</label>
                    <input
                      type="text"
                      value={stack.topics}
                      onChange={(e) => handleTechStackChange(index, 'topics', e.target.value)}
                      required
                    />
                  </div>
                </div>
              ))}
              <button type="button" onClick={handleAddTechStack}>Add More Tech Stack</button>
              <button type="button" onClick={handleSaveTechStack}>Save</button>
            </form>
      </div>
    </div>
  );
};

export default TechStackPopup;
