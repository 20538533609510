import React from 'react';



import './css/ServicesPage.css';
import FooterCertificates from '../../components/FooterCertificates';
import ImageSlider from '../../components/ImageSlider';

import ServicesSwiper from '../../components/ServicesSwiper';
import Promo from '../../components/Promo';
import DetailsSection from '../../components/ServiceDetails';

const WebDevServicePage = () => {
  const service = {
    name: 'Web Development',
    description: 'Our web development services help you create fast, secure, and user-friendly websites tailored to your business needs.',

    images: [
      '../assets/webdevelopment/webdev4.png',
      '../assets/webdevelopment/webdev1.jpg',
      // '../assets/webdevelopment/webdev2.avif',
      '../assets/webdevelopment/webdev3.avif',
     
      '../assets/webdevelopment/webdev5.png',
    ],
    explanation: [
      "Expert web and app development solutions tailored to your business needs.",
      "Seamless design and development of responsive, user-friendly websites and mobile apps.",
      "Frontend and backend development using the latest technologies.",
      "Custom web and mobile applications built for scalability and performance.",
      "Integration of APIs and third-party services for enhanced functionality.",
      "SEO-optimized websites and app store optimization (ASO) for better visibility.",
      "Secure and efficient eCommerce solutions for online businesses.",
      "Continuous maintenance and updates to keep your website and apps running smoothly.",
      "24/7 support ensuring your website and mobile apps stay online and up-to-date."
    ],
    quotes: [
      '',
      'Creating fast, user-friendly, and secure websites or apps.',
      '.',
      'Your business, powered by a dynamic online experience.'
    ],
    certificatesData: [

    ],
    swipperimages : [
      { src: '../assets/webdevelopmentSolutions/nodejs.png', caption: 'NodeJs' },
      { src:'../assets/webdevelopmentSolutions/reactjs.png', caption: 'ReactJs' },
      { src: '../assets/webdevelopmentSolutions/angular.png',caption: 'Angular' },
      { src:'../assets/webdevelopmentSolutions/python.png',caption: 'Python' },
      { src: '../assets/webdevelopmentSolutions/django.webp', caption: 'django' },
      { src:'../assets/webdevelopmentSolutions/java.png',caption: 'Java' },
      { src: '../assets/webdevelopmentSolutions/kotlin.png', caption: 'Kotlin' },
      { src: '../assets/webdevelopmentSolutions/reactnative.svg',caption: 'React Native' },
     
    ],
  };

  return (
    <section>
    {/* Top Section with Background Image and Swiper */} 
    <div className="serviceHeader">
    <ImageSlider images={service.images} quotes={service.quotes} />
    </div>

    {/* Details Section */}
    <DetailsSection images={service.images} explanation={service.explanation}/>

    {/* New Section with Background Image and Button */}

    <ServicesSwiper swipperimages={service.swipperimages}/>

<Promo/>


    {service.certificatesData.length > 0 && (
      <FooterCertificates certificates={service.certificatesData} />
    )}
  </section>
  );
};

export default WebDevServicePage;
