import React from 'react';
import './css/CustomerStories.css'; 

const stories = [
  {
    image: "../assets/testimonial/client6.jpg",
    name: "Emily Carter",
    company: "Sales Manager",
    story: "The guidance from Vijprat on Salesforce was invaluable!"
  },
  {
    image: "../assets/testimonial/client2.jpg",
    name: "Michael Johnson",
    company: "IT Director",
    story: "Thanks to Vijprat, our AWS costs were significantly reduced."
  },
  {
    image: "../assets/testimonial/client5.jpg",
    name: "Sarah Thompson",
    company: "Project Manager",
    story: "Vijprat's expertise streamlined our operations using Azure."
  },
  {
    image: "../assets/testimonial/client4.jpg",
    name: "Danish",
    company: "CTO, Digital Solutions",
    story: "Migration to Google Cloud was seamless with Vijprat’s help!"
  },
  {
    image: "../assets/testimonial/client1.jpg",
    name: "James Anderson",
    company: "CTO",
    story: "Vijprat significantly accelerated our development cycle through DevOps!"
  },
  {
    image: "../assets/testimonial/client3.jpg",
    name: "Laura Williams",
    company: "Lead Developer",
    story: "Vijprat provides excellent, quick response for application support!"
  },
  {
    image: "../assets/testimonial/client7.jpg",
    name: "David Brown",
    company: "Operations Head",
    story: "The website redesign delivered by Vijprat was impressive!"
  },
  {
    image: "../assets/testimonial/client8.jpg",
    name: "Jennifer Lee",
    company: "Marketing Manager",
    story: "Vijprat's AWS integration greatly enhanced our scalability."
  }
];


const CustomerStories = () => {
  return (
    <div className="customer-stories">
      <div className="hero-image">
        <img src="../assets/reviewbanner.jpg" alt="Customer Success" className="hero-img" />
      </div>
      <h2 data-aos="fade-right" >Customer Stories</h2>
      <div className="stories-grid">
        {stories.map((story, index) => (
          <div data-aos="tilt" key={index} className="story-card">
            <img src={`/path-to-your-images/${story.image}`} alt={story.name} className="story-image" />
            <h3>{story.name}</h3>
            <p className="company">{story.company}</p>
            <p className="story">{story.story}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CustomerStories;
